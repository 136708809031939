import React, { useState, useCallback } from 'react';
import { AlertCircle, Camera } from 'lucide-react';
import FileDropZone from './components/FileDropZone';
import FormatSelector from './components/FormatSelector';
import ConvertedFile from './components/ConvertedFile';
import DomainBanner from './components/DomainBanner';
import Footer from './components/Footer';
import { convertImage, type ConvertedImage, type OutputFormat, ConversionError } from './utils/imageConverter';

function App() {
  const [convertedImages, setConvertedImages] = useState<ConvertedImage[]>([]);
  const [error, setError] = useState<string>('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState<OutputFormat>('png');

  const handleFileConversion = useCallback(async (files: FileList) => {
    setError('');
    setIsProcessing(true);

    try {
      const newImages = await Promise.all(
        Array.from(files).map(async (file) => {
          try {
            return await convertImage(file, selectedFormat);
          } catch (err) {
            if (err instanceof ConversionError) {
              throw err;
            }
            throw new ConversionError('Failed to convert file. Please try again.');
          }
        })
      );

      setConvertedImages(prev => [...prev, ...newImages.flat()]);
    } catch (err) {
      setError(err instanceof ConversionError ? err.message : 'An unexpected error occurred.');
    } finally {
      setIsProcessing(false);
    }
  }, [selectedFormat]);

  return (
    <div className="min-h-screen tech-grid">
      <DomainBanner />
      
      <div className="relative">
        <div className="max-w-4xl mx-auto px-4 py-12 space-y-8">
          <div className="text-center space-y-6">
            <div className="relative inline-block animate-float">
              <div className="flex items-center justify-center gap-4">
                <div className="relative">
                  <div className="absolute inset-0 gradient-bg rounded-full blur-xl opacity-50 animate-pulse-glow" />
                  <Camera className="relative w-16 h-16 text-[#4ECDC4] neon-glow" />
                </div>
                <h1 className="text-6xl font-bold gradient-text neon-glow">
                  Photo UX
                </h1>
              </div>
            </div>
            <div className="relative">
              <div className="absolute inset-x-0 h-px bg-gradient-to-r from-transparent via-[#4ECDC4] to-transparent" />
              <p className="text-gray-400 text-lg font-light mt-4">
                Next-Gen Image & PDF Format Converter
              </p>
            </div>
          </div>

          <div className="space-y-6">
            <FormatSelector
              selectedFormat={selectedFormat}
              onFormatChange={setSelectedFormat}
              disabled={isProcessing}
            />
            
            <FileDropZone 
              onFilesDrop={handleFileConversion}
              isProcessing={isProcessing}
            />
          </div>

          {error && (
            <div className="p-4 glass-panel rounded-xl border-red-500/20 flex items-center gap-2 text-red-400">
              <AlertCircle className="w-5 h-5 flex-shrink-0" />
              <p>{error}</p>
            </div>
          )}

          {convertedImages.length > 0 && (
            <div className="space-y-6">
              <div className="flex items-center gap-4">
                <div className="h-px flex-grow bg-gradient-to-r from-[#4ECDC4]/50 to-transparent" />
                <h2 className="text-2xl font-semibold gradient-text neon-glow">
                  Converted Files
                </h2>
                <div className="h-px flex-grow bg-gradient-to-l from-[#4ECDC4]/50 to-transparent" />
              </div>
              <div className="grid gap-4">
                {convertedImages.map((image, index) => (
                  <ConvertedFile
                    key={`${image.originalName}-${index}`}
                    image={image}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default App;