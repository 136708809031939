import { GlobalWorkerOptions, getDocument } from 'pdfjs-dist';
import { ConversionError } from './imageConverter';

// Initialize PDF.js worker
GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

export const convertPDFToImage = async (file: File): Promise<HTMLCanvasElement[]> => {
  try {
    const arrayBuffer = await file.arrayBuffer();
    const pdf = await getDocument({ data: arrayBuffer }).promise;
    const canvases: HTMLCanvasElement[] = [];

    for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
      const page = await pdf.getPage(pageNum);
      const viewport = page.getViewport({ scale: 2.0 });
      
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      
      if (!context) {
        throw new ConversionError('Failed to create canvas context');
      }

      canvas.width = viewport.width;
      canvas.height = viewport.height;

      // Set white background
      context.fillStyle = '#ffffff';
      context.fillRect(0, 0, canvas.width, canvas.height);

      await page.render({
        canvasContext: context,
        viewport: viewport,
        background: 'white'
      }).promise;

      canvases.push(canvas);
    }

    return canvases;
  } catch (error) {
    console.error('PDF Conversion Error:', error);
    throw new ConversionError('Failed to convert PDF. Please ensure it\'s a valid PDF file.');
  }
}