import React, { useState } from 'react';
import { Download, X } from 'lucide-react';
import type { ConvertedImage } from '../utils/imageConverter';

interface ConvertedFileProps {
  image: ConvertedImage;
}

const ConvertedFile: React.FC<ConvertedFileProps> = ({ image }) => {
  const [showPreview, setShowPreview] = useState(false);

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = image.url;
    link.download = image.originalName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div className="group relative hover-scale">
        <div className="absolute -inset-0.5 gradient-bg rounded-xl blur opacity-30 group-hover:opacity-100 transition duration-500" />
        <div className="relative glass-panel rounded-xl overflow-hidden">
          <div className="p-4 flex items-center gap-4">
            <button
              onClick={() => setShowPreview(true)}
              className="relative w-16 h-16 rounded-lg overflow-hidden flex-shrink-0 focus:outline-none focus:ring-2 focus:ring-[#4ECDC4] focus:ring-offset-2 focus:ring-offset-[#1F1D2B] hover-scale"
            >
              <div className="absolute inset-0 gradient-bg opacity-50 group-hover:opacity-75 transition-opacity" />
              <img
                src={image.url}
                alt={image.originalName}
                className="absolute inset-0 w-full h-full object-cover"
              />
            </button>
            
            <div className="flex-grow min-w-0">
              <h3 className="font-medium text-[#4ECDC4] truncate neon-glow">
                {image.originalName}
              </h3>
              <div className="flex flex-wrap gap-x-4 text-sm text-[#4ECDC4]/80">
                <span>{image.originalFormat} → {image.targetFormat}</span>
                <span>{image.size} KB</span>
                <span>{image.dimensions.width} × {image.dimensions.height}</span>
              </div>
            </div>
            
            <button
              onClick={handleDownload}
              className="relative group/btn"
            >
              <div className="absolute -inset-2 gradient-bg rounded-xl blur-lg opacity-0 group-hover/btn:opacity-30 transition-opacity duration-300" />
              <div className="relative flex items-center justify-center w-12 h-12 rounded-xl gradient-border p-0.5">
                <div className="relative w-full h-full rounded-xl glass-panel flex items-center justify-center hover-scale">
                  <Download className="w-5 h-5 text-[#4ECDC4]" />
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>

      {showPreview && (
        <div className="fixed inset-0 bg-black/80 backdrop-blur-xl flex items-center justify-center z-50 p-4">
          <div className="relative max-w-4xl w-full">
            <div className="absolute -inset-0.5 gradient-bg rounded-2xl blur opacity-30" />
            <div className="relative glass-panel rounded-2xl overflow-hidden">
              <div className="absolute top-4 right-4 z-10">
                <button
                  onClick={() => setShowPreview(false)}
                  className="p-2 rounded-xl glass-panel hover:bg-white/10 text-white transition-colors hover-scale"
                >
                  <X className="w-6 h-6" />
                </button>
              </div>
              <div className="relative aspect-video bg-[#1F1D2B]">
                <img
                  src={image.url}
                  alt={image.originalName}
                  className="absolute inset-0 w-full h-full object-contain"
                />
              </div>
              <div className="p-4">
                <h3 className="font-medium text-[#4ECDC4] neon-glow">
                  {image.originalName}
                </h3>
                <div className="flex flex-wrap gap-x-4 text-sm text-[#4ECDC4]/80 mt-1">
                  <span>{image.originalFormat} → {image.targetFormat}</span>
                  <span>{image.size} KB</span>
                  <span>{image.dimensions.width} × {image.dimensions.height}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConvertedFile;