import { FORMAT_CONFIG, SUPPORTED_FORMATS } from './constants';
import { getFileExtension } from './fileUtils';
import { convertPDFToImage } from './pdfConverter';

export type OutputFormat = keyof typeof FORMAT_CONFIG;

export interface ConvertedImage {
  originalName: string;
  url: string;
  size: number;
  originalFormat: string;
  targetFormat: string;
  dimensions: {
    width: number;
    height: number;
  };
}

export class ConversionError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ConversionError';
  }
}

const getOriginalFormat = (file: File): string => {
  const extension = getFileExtension(file.name);
  switch (extension) {
    case 'webp':
      return 'WebP';
    case 'jpg':
    case 'jpeg':
      return 'JPEG';
    case 'tiff':
    case 'tif':
      return 'TIFF';
    case 'png':
      return 'PNG';
    case 'pdf':
      return 'PDF';
    default:
      throw new ConversionError('Unsupported file format');
  }
};

const createImage = (url: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = () => reject(new ConversionError('Failed to load image'));
    img.src = url;
  });
};

export const convertImage = async (file: File, format: OutputFormat): Promise<ConvertedImage[]> => {
  const extension = getFileExtension(file.name);
  
  if (!SUPPORTED_FORMATS.input.includes(extension)) {
    throw new ConversionError('Unsupported file format. Please upload a WebP, JPEG, TIFF, PNG, or PDF file.');
  }

  const isPDF = extension === 'pdf';
  let canvases: HTMLCanvasElement[] = [];

  if (isPDF) {
    canvases = await convertPDFToImage(file);
  } else {
    const url = URL.createObjectURL(file);
    try {
      const img = await createImage(url);
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        throw new ConversionError('Failed to create canvas context');
      }

      ctx.drawImage(img, 0, 0);
      canvases = [canvas];
    } finally {
      URL.revokeObjectURL(url);
    }
  }

  const { mimeType, quality } = FORMAT_CONFIG[format];

  return canvases.map((canvas, index) => {
    const dataUrl = canvas.toDataURL(mimeType, quality);
    const size = Math.round((dataUrl.length - 22) * 0.75 / 1024);
    const pageNumber = canvases.length > 1 ? `-page${index + 1}` : '';

    return {
      originalName: file.name.replace(/\.[^/.]+$/, `${pageNumber}.${format}`),
      url: dataUrl,
      size,
      originalFormat: getOriginalFormat(file),
      targetFormat: FORMAT_CONFIG[format].label,
      dimensions: {
        width: canvas.width,
        height: canvas.height
      }
    };
  });
};