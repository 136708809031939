import React from 'react';
import { Mail, ExternalLink } from 'lucide-react';

const Footer: React.FC = () => {
  return (
    <footer className="relative mt-24 pb-12">
      <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />
      
      <div className="relative max-w-4xl mx-auto px-4">
        <div className="glass-panel rounded-2xl p-8 space-y-8 border border-white/20">
          <div className="text-center space-y-2">
            <h2 className="text-2xl font-semibold gradient-text neon-glow">
              This Domain is for Sale
            </h2>
            <div className="h-px w-32 mx-auto bg-gradient-to-r from-transparent via-[#4ECDC4] to-transparent" />
          </div>

          <div className="space-y-6 text-gray-200">
            <p className="leading-relaxed">
              You can find this domain listed on{' '}
              <a href="https://opensea.io" target="_blank" rel="noopener noreferrer" className="text-[#4ECDC4] hover:text-[#5DEDDF] hover:underline inline-flex items-center gap-1 font-medium">
                OpenSea <ExternalLink className="w-3 h-3" />
              </a>
              ,{' '}
              <a href="https://unstoppabledomains.com" target="_blank" rel="noopener noreferrer" className="text-[#4ECDC4] hover:text-[#5DEDDF] hover:underline inline-flex items-center gap-1 font-medium">
                Unstoppable Marketplace <ExternalLink className="w-3 h-3" />
              </a>
              , and{' '}
              <a href="https://www.atom.com" target="_blank" rel="noopener noreferrer" className="text-[#4ECDC4] hover:text-[#5DEDDF] hover:underline inline-flex items-center gap-1 font-medium">
                Atom <ExternalLink className="w-3 h-3" />
              </a>
              . As a tokenized website, the associated token will be transferred with the domain on OpenSea and Unstoppable Marketplace (Sedo support coming soon). If tokenization isn't essential now, it can always be added later by transferring the domain to the Unstoppable Marketplace.
            </p>

            <p className="text-center text-gray-200">
              This is a demo site designed to help you convert images into the format you need.
            </p>
          </div>

          <div className="pt-6 border-t border-white/20">
            <div className="text-center space-y-4">
              <h3 className="text-xl font-medium text-white">Contact Us</h3>
              <p className="text-gray-200">
                Have questions or want to inquire about purchasing this domain?
              </p>
              <div className="flex flex-col items-center gap-4">
                <a
                  href="mailto:Stephanie@DomainOfThings.com"
                  className="inline-flex items-center gap-2 px-6 py-3 rounded-xl bg-[#4ECDC4]/10 hover:bg-[#4ECDC4]/20 border border-[#4ECDC4]/30 hover:border-[#4ECDC4]/50 transition-all duration-300 hover-scale group"
                >
                  <Mail className="w-5 h-5 text-white group-hover:text-[#4ECDC4]" />
                  <span className="text-white font-medium group-hover:text-[#4ECDC4]">
                    Stephanie@DomainOfThings.com
                  </span>
                </a>
                <a
                  href="https://x.com/PhotoUX"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center gap-2 px-6 py-3 rounded-xl bg-[#4ECDC4]/10 hover:bg-[#4ECDC4]/20 border border-[#4ECDC4]/30 hover:border-[#4ECDC4]/50 transition-all duration-300 hover-scale group"
                >
                  <svg className="w-5 h-5 text-white group-hover:text-[#4ECDC4]" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
                  </svg>
                  <span className="text-white font-medium group-hover:text-[#4ECDC4]">
                    Follow us on 𝕏
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;