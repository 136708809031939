import React from 'react';
import { ExternalLink } from 'lucide-react';

const DomainBanner: React.FC = () => {
  return (
    <div className="relative z-50">
      <div className="absolute inset-0 bg-gradient-to-r from-[#FF6B6B] via-[#4ECDC4] to-[#556270]">
        <div className="absolute inset-0 bg-gradient-to-r from-[#FF6B6B]/50 via-[#4ECDC4]/50 to-[#556270]/50">
          <div className="w-full h-full animate-shimmer bg-[linear-gradient(110deg,transparent_25%,rgba(255,255,255,0.2)_50%,transparent_75%)] bg-[length:200%_100%]" />
        </div>
      </div>
      <a
        href="https://www.atom.com/name/PhotoUX.com"
        target="_blank"
        rel="noopener noreferrer"
        className="block relative bg-black/20 backdrop-blur-sm hover:bg-black/30 transition-all duration-300 group"
      >
        <div className="max-w-4xl mx-auto px-4">
          <div className="py-4 flex items-center justify-center gap-6">
            <div className="flex items-center gap-3">
              <div className="w-3 h-3 bg-white rounded-full animate-pulse" />
              <p className="text-white font-medium text-xl">
                PhotoUX.com - Premium Domain For Sale
              </p>
              <div className="w-3 h-3 bg-white rounded-full animate-pulse" />
            </div>
            <div className="flex items-center gap-2 px-4 py-1.5 rounded-lg bg-white/10 text-white font-medium group-hover:bg-white/20 transition-all duration-300 border border-white/20">
              <span>Make Offer</span>
              <ExternalLink className="w-4 h-4" />
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default DomainBanner;